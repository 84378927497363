import { Settings } from "../Helpers";

const Resources = {
  PRIVACY_POLICY: Settings.pickerMode
    ? "Zekat Hesaplama Privacy Policy"
    : "Zekat Hesapla Privacy Policy",
  TERMS_AND_CONDITIONS: Settings.pickerMode
    ? "Zekat Hesaplama Terms and Conditions"
    : "Zekat Hesapla Terms and Conditions",
};

export default Resources;
