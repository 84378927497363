import React from "react";

const Component = ({ children, bottom, left, right, top }) => (
  <div
    style={{
      display: "inline-block",
      marginBottom: bottom,
      marginRight: right,
      marginLeft: left,
      marginTop: top,
    }}
  >
    {children}
  </div>
);

export default Component;
