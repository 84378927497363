import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  HomeScreen,
  BlogScreen,
  PostScreen,
  StaticScreen,
  LostScreen,
} from "../Pages";

class Component extends React.PureComponent {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/blog" component={BlogScreen} />
          <Route exact path="/blog/:blog" component={PostScreen} />
          <Route exact path="/static/:static" component={StaticScreen} />
          <Route path="/404" component={LostScreen} />
          <Redirect from="*" to="/404" />
        </Switch>
      </Router>
    );
  }
}

export default Component;
