import React from "react";

import Text from "../Atoms/Text";

const Component = ({ children, bottom, style, top, align }) => (
  <Text
    size={22}
    line={34}
    weight="600"
    block
    bottom={bottom}
    style={style}
    top={top}
    align={align}
    type="Title"
  >
    {children}
  </Text>
);

export default Component;
