import React from "react";
import { Colors } from "../../Helpers";
import Text from "../Atoms/Text";

const Component = ({
  children,
  bottom,
  align,
  color = Colors.independence,
  opacity,
  size = 18,
  style,
}) => (
  <Text
    style={style}
    size={size}
    line={32}
    weight="400"
    color={color}
    align={align}
    block
    bottom={bottom}
    opacity={opacity}
    type="Paragraph"
  >
    {children}
  </Text>
);

export default Component;
