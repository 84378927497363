import React from "react";
import { connect } from "react-redux";
import Moment from "moment/min/moment-with-locales";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import {
  Title,
  Button,
  Align,
  Input,
  Paragraph,
  Spacer,
  Text,
  Icon,
  Padding,
  Show,
} from "../Components";
import { Types, Fields, Colors, Settings } from "../Helpers";
import { API } from "../Modules";

const Select = ({ children, value, onChange }) => (
  <select
    value={value}
    onChange={onChange}
    style={{
      flex: 1,
      height: 35,
      width: 80,
      lineHeight: "35px",
      paddingLeft: 8,
      paddingRight: 8,
      borderStyle: "solid",
      borderRadius: 8,
      borderWidth: 2,
      borderColor: Colors.platinum,
      fontWeight: "500",
      fontSize: 16,
      color: "rgb(60, 62, 70)",
      textDecoration: "none",
      cursor: "pointer",
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </select>
);

const Info = ({ children }) => (
  <ScreenClassRender
    render={(screen) => (
      <Spacer bottom={40}>
        <div
          style={{
            padding: ["xs"].includes(screen) ? "8px 12px" : "12px 24px",
            background: "rgba(23,92,255,0.04)",
            borderRadius: 8,
            display: "flex",
            alignItems: ["xs"].includes(screen) ? "flex-start" : "center",
          }}
        >
          <Icon src="i/icon-info.svg" color="rgba(23,92,255)" right={8} />
          <Text
            weight="500"
            size={["xs"].includes(screen) ? 16 : 18}
            color="rgba(23,92,255)"
          >
            {children}
          </Text>
        </div>
      </Spacer>
    )}
  />
);

function stringToMoneyNumber(value) {
  var data = value.split(" ")[0];

  data = data.replace(/\./g, "");
  data = data.replace(/,/g, ".");

  return parseFloat(data);
}

function money(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        properties: Fields,
        debts: Fields,
      },
      fields: {
        properties: [Fields[0], Fields[1], Fields[2]],
        debts: [Fields[0], Fields[1], Fields[2]],
      },
      properties: {},
      debts: {},
      showCurrencyDebt: false,
      showAssetDebt: false,
      showCurrencyProperty: false,
      showAssetProperty: false,
      changed: false,
      selectOptionProperty: "DANİMARKA KRONU",
      selectOptionDebt: "DANİMARKA KRONU",
      values: [],
    };

    this.changeValueOfPropertyOrDebt = this.changeValueOfPropertyOrDebt.bind(
      this
    );
    this.calculate = this.calculate.bind(this);
    Moment.locale("tr");
  }

  componentDidMount() {
    var fields = Fields;
    fields.splice(0, 3);

    this.setState({
      options: { properties: fields, debts: fields },
      selectOptionProperty: fields[0].type,
      selectOptionDebt: fields[0].type,
    });
  }

  changeValueOfPropertyOrDebt(type, key, value) {
    if (type === "debt") {
      var debts = this.state.debts;
      debts[key] = value;
      this.setState({ debts });
    }

    if (type === "property") {
      var properties = this.state.properties;
      properties[key] = value;
      this.setState({ properties });
    }
  }

  calculate() {
    var properties = 0;
    var debts = 0;
    var values = {};

    for (let [key, value] of Object.entries(this.state.properties)) {
      if (key !== "TRY") {
        properties += API.convert(key, value);
        values[key] = API.convert(key, 1);
      } else {
        properties += value;
      }
    }

    for (let [key, value] of Object.entries(this.state.debts)) {
      if (key !== "TRY") {
        debts += API.convert(key, value);

        values[key] = API.convert(key, 1);
      } else {
        debts += value;
      }
    }

    const datas = [];

    for (let [key, value] of Object.entries(values)) {
      Fields.map((field) => {
        if (field.type === key) {
          datas.push({ label: field.label, value });
        }

        return true;
      });
    }

    this.setState({ values: datas });

    var total = properties - debts;

    this.props.core("total", total);
    this.props.core("properties", properties);
    this.props.core("debts", debts);
    this.props.core("total", total);
    this.props.core("minimum", API.convert(Types.gold.GRAM, 80.18));
    this.props.core("show", { result: true });

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <ScreenClassRender
        render={(screen) => (
          <Container
            style={{ paddingTop: this.props.redux.show.result ? 12 : 80 }}
            key="container1"
          >
            {this.props.redux.show.result ? (
              <>
                <Title bottom={16}>Zekat Hesabı Sonucunuz</Title>
                <Padding
                  top={["xs"].includes(screen) ? 14 : 24}
                  right={["xs"].includes(screen) ? 14 : 24}
                  bottom={["xs"].includes(screen) ? 14 : 24}
                  left={["xs"].includes(screen) ? 14 : 24}
                  display="block"
                  style={{
                    borderRadius: 6,
                    border: "1px solid rgba(0,0,0,0.09)",
                    boxSizing: "border-box",
                  }}
                >
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row justify="space-between">
                        <Col xs={12} sm={12} md={8}>
                          <div>
                            <Title>
                              Toplam Varlık:{" "}
                              {money(this.props.redux.properties)} TL
                            </Title>
                            <Title>
                              Toplam Borç: {money(this.props.redux.debts)} TL
                            </Title>
                            <Title bottom={24}>
                              Net Varlık: {money(this.props.redux.total)} TL
                            </Title>
                            <Paragraph bottom={24} opacity={0.6}>
                              Nisap Miktarı (80,18 Gram Altın):{" "}
                              {money(this.props.redux.minimum)} TL
                            </Paragraph>
                          </div>
                        </Col>

                        {["xs"].includes(screen) ? null : (
                          <Col xs={12} sm={12} md={4}>
                            <Align justify="flex-end">
                              <Button
                                click={() => {
                                  this.props.core("show", { result: false });
                                  window.scrollTo(0, 0);
                                }}
                                bottom={24}
                              >
                                Hesabı Tekrarla
                              </Button>
                            </Align>
                          </Col>
                        )}
                      </Row>
                      {this.props.redux.total >= this.props.redux.minimum ? (
                        <div>
                          <Title>
                            Vermeniz Gereken Zekat:{" "}
                            {money(this.props.redux.total * 0.025)} TL
                          </Title>
                          <Paragraph bottom={24} opacity={0.6}>
                            (Net Varlığın %2,5'i)
                          </Paragraph>
                        </div>
                      ) : (
                        <div>
                          <Title>Vermeniz Gereken Zekat: Yok</Title>
                          <Paragraph bottom={24} opacity={0.6}>
                            Net varlığınız nisap miktarından düşük olduğu için
                            zekat vermeniz gerekmiyor.
                          </Paragraph>
                        </div>
                      )}
                      {["xs"].includes(screen) ? (
                        <Button
                          click={() => {
                            this.props.core("show", { result: false });
                            window.scrollTo(0, 0);
                          }}
                          bottom={24}
                        >
                          Hesabı Tekrarla
                        </Button>
                      ) : null}
                      <Paragraph bottom={8} top={24} size={14}>
                        Bu zekat hesabı {Moment().format("DD MMMM YYYY")}{" "}
                        tarihinde {Moment().format("HH:mm:ss")} saatindeki
                        piyasa verileri kullanılarak yapılmıştır.
                      </Paragraph>
                      <Paragraph
                        bottom={24}
                        size={14}
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {this.state.values.map((value, v) => (
                          <span
                            key={value.label}
                            style={{ whiteSpace: "nowrap", marginRight: 6 }}
                          >
                            {value.label +
                              " " +
                              money(value.value, 2, ".") +
                              (this.state.values.length - 1 !== v ? " | " : "")}
                          </span>
                        ))}
                      </Paragraph>
                    </Col>
                  </Row>
                </Padding>
              </>
            ) : (
              <>
                <Title bottom={16}>Zekat Hesaplama Aracı</Title>
                <Padding
                  top={["xs"].includes(screen) ? 14 : 24}
                  right={["xs"].includes(screen) ? 14 : 24}
                  bottom={["xs"].includes(screen) ? 14 : 24}
                  left={["xs"].includes(screen) ? 14 : 24}
                  display="block"
                  style={{
                    borderRadius: 6,
                    border: "1px solid rgba(0,0,0,0.09)",
                    boxSizing: "border-box",
                  }}
                >
                  <Row align="center" justify="center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Info>
                        {Settings.pickerMode
                          ? "Tahsilatı kesin olan alacaklarınızı varlıklarınıza ekleyin. Seçeneklerden para, altın veya gümüş seçebilirsiniz."
                          : "Tahsilatı kesin olan alacaklarınızı varlıklarınıza ekleyin."}
                      </Info>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                          <div
                            style={{
                              position: "sticky",
                              top: 0,
                              background: "white",
                              display: "block",
                              zIndex: 3,
                            }}
                          >
                            <Title bottom={24} top={24}>
                              Varlıklarım
                            </Title>
                          </div>
                          {this.state.fields.properties.map((field, ___) => (
                            <Input
                              key={field.type}
                              value={this.state.properties[field.type]}
                              placeholder={field.placeholder}
                              label={field.label}
                              icon={field.icon}
                              bottom={12}
                              currency={field.currency}
                              suffix={field.suffix}
                              change={(event) =>
                                this.changeValueOfPropertyOrDebt(
                                  "property",
                                  field.type,
                                  stringToMoneyNumber(event.target.value)
                                )
                              }
                            />
                          ))}
                          {this.state.options.properties.length > 0 ? (
                            <Align align="center" justify="space-between">
                              <Select
                                value={this.state.selectOptionProperty}
                                onChange={(event) =>
                                  this.setState({
                                    selectOptionProperty: event.target.value,
                                  })
                                }
                              >
                                {this.state.options.properties.map(
                                  (field, ___) => (
                                    <option
                                      key={field.type}
                                      value={field.type}
                                      disabled={field.disabled}
                                    >
                                      {field.label}
                                    </option>
                                  )
                                )}
                              </Select>
                              <Button
                                type="tiny"
                                click={() => {
                                  this.state.options.properties.map(
                                    (field, f) => {
                                      if (
                                        field.type ===
                                          this.state.selectOptionProperty &&
                                        !field.disabled
                                      ) {
                                        var properties = this.state.options.properties.slice();
                                        const debts = this.state.options.debts.slice();
                                        properties.splice(f, 1);
                                        const options = { debts, properties };

                                        var fields = this.state.fields;
                                        fields.properties.push(field);

                                        try {
                                          this.setState({
                                            options,
                                            fields,
                                            selectOptionProperty: properties[0]
                                              ? properties[0].disabled
                                                ? properties[1].disabled
                                                  ? properties[2].disabled
                                                    ? properties[3].type
                                                    : properties[2].type
                                                  : properties[1].type
                                                : properties[0].type
                                              : undefined,
                                          });
                                        } catch (Error) {
                                          console.log(Error);
                                        }
                                      }
                                      return true;
                                    }
                                  );
                                }}
                              >
                                Varlık Ekle
                              </Button>
                            </Align>
                          ) : null}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                          <div
                            style={{
                              position: "sticky",
                              top: 0,
                              background: "white",
                              display: "block",
                              zIndex: 3,
                            }}
                          >
                            <Title bottom={24} top={24}>
                              Borçlarım
                            </Title>
                          </div>
                          {this.state.fields.debts.map((field) => (
                            <Input
                              key={field.type}
                              value={this.state.debts[field.type]}
                              placeholder={field.placeholder}
                              label={field.label}
                              icon={field.icon}
                              bottom={12}
                              currency={field.currency}
                              suffix={field.suffix}
                              change={(event) =>
                                this.changeValueOfPropertyOrDebt(
                                  "debt",
                                  field.type,
                                  stringToMoneyNumber(event.target.value)
                                )
                              }
                            />
                          ))}
                          {this.state.options.debts.length > 0 ? (
                            <Align align="center" justify="space-between">
                              <Select
                                value={this.state.selectOptionDebt}
                                onChange={(event) =>
                                  this.setState({
                                    selectOptionDebt: event.target.value,
                                  })
                                }
                              >
                                {this.state.options.debts.map((field) => (
                                  <option
                                    key={field.type}
                                    value={field.type}
                                    disabled={field.disabled}
                                  >
                                    {field.label}
                                  </option>
                                ))}
                              </Select>
                              <Button
                                type="tiny"
                                click={() => {
                                  this.state.options.debts.map((field, f) => {
                                    if (
                                      field.type ===
                                        this.state.selectOptionDebt &&
                                      !field.disabled
                                    ) {
                                      const properties = this.state.options.properties.slice();
                                      var debts = this.state.options.debts.slice();
                                      debts.splice(f, 1);
                                      const options = { debts, properties };

                                      var fields = this.state.fields;
                                      fields.debts.push(field);

                                      this.setState({
                                        options,
                                        fields,
                                        selectOptionDebt: debts[0]
                                          ? debts[0].disabled
                                            ? debts[1].disabled
                                              ? debts[2].disabled
                                                ? debts[3].type
                                                : debts[2].type
                                              : debts[1].type
                                            : debts[0].type
                                          : undefined,
                                      });
                                    }
                                    return true;
                                  });
                                }}
                              >
                                Borç Ekle
                              </Button>
                            </Align>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Show show={false}>
                    <Input
                      key="TEST"
                      value="TEST"
                      placeholder="TEST"
                      label="TEST"
                      icon={undefined}
                      bottom={12}
                      currency="TEST"
                      suffix="TEST"
                    />
                  </Show>
                  <Align align="center" justify="center" top={48}>
                    <Button click={this.calculate} type="active">
                      Hesapla
                    </Button>
                    <Button
                      click={() => this.setState({ debts: {}, properties: {} })}
                      style={{ marginLeft: 12 }}
                    >
                      Temizle
                    </Button>
                  </Align>
                </Padding>
              </>
            )}
          </Container>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
