import React from "react";
import CurrencyInput from "react-currency-input";
import { ScreenClassRender } from "react-grid-system";

import { Colors } from "../../Helpers";
import { Text, Icon } from "../../Components";

const Style = {
  active: {
    backgroundColor: "rgba(23,92,255,0.04)",
    height: 48,
    borderRadius: 8,
    boxSizing: "border-box",
    padding: 12,
    color: Colors.ultramarine,
    display: "flex",
    alignItems: "center",
  },
  default: {
    backgroundColor: "rgba(60,62,70,0.04)",
    height: 48,
    borderRadius: 8,
    boxSizing: "border-box",
    padding: 12,
    color: "#3C3E46",
    display: "flex",
    alignItems: "center",
  },
};

const Types = {
  colors: {
    active: Colors.ultramarine,
    default: "#3C3E46",
  },
};

const Component = ({
  type = "default",
  value,
  placeholder,
  icon,
  label,
  bottom,
  engine = "number",
  change,
  currency,
  suffix,
  precision = "0",
}) => (
  <ScreenClassRender
    render={(screen) => (
      <div>
        {label && ["xs"].includes(screen) ? (
          <Text
            line={24}
            size={16}
            color={Types.colors[type]}
            right={24}
            weight="600"
          >
            {label}
          </Text>
        ) : null}
        <div style={{ ...Style[type], marginBottom: bottom }}>
          {icon ? (
            <Icon src={icon} color={Types.colors[type]} right={8} />
          ) : null}
          {label && !["xs"].includes(screen) ? (
            <Text
              line={24}
              size={18}
              color={Types.colors[type]}
              right={24}
              weight="600"
            >
              {label}
            </Text>
          ) : null}
          {currency ? (
            <CurrencyInput
              value={value}
              precision={"2"}
              thousandSeparator="."
              decimalSeparator=","
              onChangeEvent={change}
              suffix={suffix}
              autoFocus={false}
              style={{
                border: "none",
                fontSize: 18,
                fontFamily: "Gilroy",
                backgroundColor: "transparent",
                outline: "none",
                flex: 1,
                paddingLeft: 24,
                fontWeight: "500",
                textAlign: "right",
                boxSizing: "border-box",
                width: 50,
              }}
            />
          ) : (
            <CurrencyInput
              value={value}
              precision={"0"}
              thousandSeparator="."
              decimalSeparator=","
              onChangeEvent={change}
              suffix={suffix}
              autoFocus={false}
              style={{
                border: "none",
                fontSize: 18,
                fontFamily: "Gilroy",
                backgroundColor: "transparent",
                outline: "none",
                flex: 1,
                paddingLeft: 24,
                fontWeight: "500",
                textAlign: "right",
                boxSizing: "border-box",
                width: 50,
              }}
            />
          )}
        </div>
      </div>
    )}
  />
);

export default Component;
