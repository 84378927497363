import Types from "./Types";
import Lodash from "lodash";

const FirstFields = [
  {
    type: Types.currency.TRY,
    placeholder: "Miktar...",
    label: "Türk Lirası",
    icon: "/i/icon-try-default.svg",
    currency: true,
    suffix: " TL",
  },
  {
    type: Types.currency.USD,
    placeholder: "Miktar...",
    label: "Amerikan Doları",
    icon: "/i/icon-usd-default.svg",
    currency: true,
    suffix: " USD",
  },
  {
    type: Types.currency.EUR,
    placeholder: "Miktar...",
    label: "Euro",
    icon: "/i/icon-eur-default.svg",
    currency: true,
    suffix: " EUR",
  },
];

var Fields = [
  ...FirstFields,
  {
    type: "DANİMARKA KRONU",
    placeholder: "Miktar...",
    label: "Danimarka Kronu",
    icon: undefined,
    currency: true,
    suffix: " DKK",
    selected: true,
  },
  {
    type: "AVUSTRALYA DOLARI",
    placeholder: "Miktar...",
    label: "Avustralya Doları",
    icon: undefined,
    currency: true,
    suffix: " AUD",
  },
  {
    type: "İNGİLİZ STERLİNİ",
    placeholder: "Miktar...",
    label: "İngiliz Sterlini",
    icon: undefined,
    currency: true,
    suffix: " GBP",
  },
  {
    type: "İSVİÇRE FRANGI",
    placeholder: "Miktar...",
    label: "İsviçre Frangı",
    icon: undefined,
    currency: true,
    suffix: " CHF",
  },
  {
    type: "İSVEÇ KRONU",
    placeholder: "Miktar...",
    label: "İsveç Kronu",
    icon: undefined,
    currency: true,
    suffix: " SEK",
  },
  {
    type: "KANADA DOLARI",
    placeholder: "Miktar...",
    label: "Kanada Doları",
    icon: undefined,
    currency: true,
    suffix: " CAD",
  },
  {
    type: "KUVEYT DİNARI",
    placeholder: "Miktar...",
    label: "Kuveyt Dinarı",
    icon: undefined,
    currency: true,
    suffix: " KWD",
  },
  {
    type: "NORVEÇ KRONU",
    placeholder: "Miktar...",
    label: "Norveç Kronu",
    icon: undefined,
    currency: true,
    suffix: " NOK",
  },
  {
    type: "SUUDİ ARABİSTAN RİYALİ",
    placeholder: "Miktar...",
    label: "S. Arabistan Riyali",
    icon: undefined,
    currency: true,
    suffix: " SAR",
  },
  {
    type: "JAPON YENİ",
    placeholder: "Miktar...",
    label: "Japon Yeni",
    icon: undefined,
    currency: true,
    suffix: " JPY",
  },
  {
    type: "BULGAR LEVASI",
    placeholder: "Miktar...",
    label: "Bulgar Levası",
    icon: undefined,
    currency: true,
    suffix: " BGN",
  },
  {
    type: "RUMEN LEYİ",
    placeholder: "Miktar...",
    label: "Rumen Leyi",
    icon: undefined,
    currency: true,
    suffix: " RON",
  },
  {
    type: "RUS RUBLESİ",
    placeholder: "Miktar...",
    label: "Rus Rublesi",
    icon: undefined,
    currency: true,
    suffix: " RUB",
  },
  {
    type: "İRAN RİYALİ",
    placeholder: "Miktar...",
    label: "İran Riyali",
    icon: undefined,
    currency: true,
    suffix: " IRR",
  },
  {
    type: "ÇİN YUANI",
    placeholder: "Miktar...",
    label: "Çin Yuanı",
    icon: undefined,
    currency: true,
    suffix: " CNY",
  },
  {
    type: "PAKİSTAN RUPİSİ",
    placeholder: "Miktar...",
    label: "Pakistan Rupisi",
    icon: undefined,
    currency: true,
    suffix: " PKR",
  },
  {
    type: "KATAR RİYALİ",
    placeholder: "Miktar...",
    label: "Katar Riyali",
    icon: undefined,
    currency: true,
    suffix: " QAR",
  },
  {
    type: "Gram Altın",
    placeholder: "Gram...",
    label: "Gram Altın",
    icon: undefined,
    currency: true,
    suffix: " Gram",
    gold: true,
  },
  {
    type: "Çeyrek Altın",
    placeholder: "Adet...",
    label: "Çeyrek Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "22 Ayar Bilezik",
    placeholder: "Gram...",
    label: "22 Ayar Bilezik",
    icon: undefined,
    currency: true,
    suffix: " Gram",
    gold: true,
  },
  {
    type: "Ons Altın",
    placeholder: "Adet...",
    label: "Ons Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Yarım Altın",
    placeholder: "Adet...",
    label: "Yarım Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Tam Altın",
    placeholder: "Adet...",
    label: "Tam Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Cumhuriyet Altını",
    placeholder: "Adet...",
    label: "Cumhuriyet Altını",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Ata Altın",
    placeholder: "Adet...",
    label: "Ata Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Reşat Altın",
    placeholder: "Adet...",
    label: "Reşat Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Hamit Altın",
    placeholder: "Adet...",
    label: "Hamit Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "İkibuçuk Altın",
    placeholder: "Adet...",
    label: "İkibuçuk Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Gremse Altın",
    placeholder: "Adet...",
    label: "Gremse Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "Beşli Altın",
    placeholder: "Adet...",
    label: "Beşli Altın",
    icon: undefined,
    currency: false,
    suffix: " Adet",
    gold: true,
  },
  {
    type: "14 Ayar Altın",
    placeholder: "Gram...",
    label: "14 Ayar Altın",
    icon: undefined,
    currency: true,
    suffix: " Gram",
    gold: true,
  },
  {
    type: "18 Ayar Altın",
    placeholder: "Gram...",
    label: "18 Ayar Altın",
    icon: undefined,
    currency: true,
    suffix: " Gram",
    gold: true,
  },
  {
    type: "Gümüş",
    placeholder: "Gram...",
    label: "Gümüş",
    icon: undefined,
    currency: true,
    suffix: " Gram",
    silver: true,
  },
];

var OnlyCurrencyFields = Lodash.map(Fields, (field, ___) => {
  if (field.placeholder === "Miktar..." && ___ > 2) {
    return field;
  }
});

OnlyCurrencyFields = Lodash.without(OnlyCurrencyFields, undefined);
OnlyCurrencyFields = Lodash.orderBy(OnlyCurrencyFields, "label", "desc");

var OnlyGoldFields = Lodash.map(Fields, (field, ___) => {
  if (field.gold === true) {
    return field;
  }
});

OnlyGoldFields = Lodash.without(OnlyGoldFields, undefined);

var OnlySilverFields = Lodash.map(Fields, (field, ___) => {
  if (field.silver === true) {
    return field;
  }
});

OnlySilverFields = Lodash.without(OnlySilverFields, undefined);

const RealFields = [
  ...FirstFields,
  ...OnlyCurrencyFields,
  {
    type: "GOLD",
    placeholder: false,
    label: "ALTIN",
    icon: undefined,
    currency: false,
    suffix: false,
    disabled: true,
  },
  ...OnlyGoldFields,
  {
    type: "SILVER",
    placeholder: false,
    label: "GÜMÜŞ",
    icon: undefined,
    currency: false,
    suffix: false,
    disabled: true,
  },
  ...OnlySilverFields,
];

export default RealFields;
