import React from "react";

const Component = ({
  children,
  bottom,
  left,
  right,
  top,
  display = "inline-block",
  style,
}) => (
  <div
    style={{
      display: display,
      paddingBottom: bottom,
      paddingRight: right,
      paddingLeft: left,
      paddingTop: top,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Component;
