import React from "react";

const Component = ({ src, size = 24, right, color }) => (
  <div
    style={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: size,
      width: size,
      marginRight: right,
      tintColor: color,

    }}
  >
    <img
      src={src}
      alt={src}
      style={{ height: size, width: size, pointerEvents: "none" }}
    />
  </div>
);

export default Component;
