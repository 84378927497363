import React from "react";

import { Colors } from "../../Helpers";

const href = undefined;

const Component = ({
  children,
  size,
  weight,
  color = Colors.space,
  line,
  block,
  bottom = 0,
  opacity = 1,
  align = "left",
  style,
  click,
  top = 0,
  type,
}) =>
  type === "Header" ? (
    <h1
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color,
        lineHeight: line ? line + "px" : undefined,
        display: block ? "block" : "inline-block",
        marginBottom: bottom,
        marginTop: top,
        opacity: opacity,
        textAlign: align,
        cursor: click ? "pointer" : undefined,
        ...style,
      }}
      onClick={click}
    >
      {children}
    </h1>
  ) : type === "Title" ? (
    <h2
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color,
        lineHeight: line ? line + "px" : undefined,
        display: block ? "block" : "inline-block",
        marginBottom: bottom,
        marginTop: top,
        opacity: opacity,
        textAlign: align,
        cursor: click ? "pointer" : undefined,
        ...style,
      }}
      onClick={click}
    >
      {children}
    </h2>
  ) : type === "Subtitle" ? (
    <h6
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color,
        lineHeight: line ? line + "px" : undefined,
        display: block ? "block" : "inline-block",
        marginBottom: bottom,
        marginTop: top,
        opacity: opacity,
        textAlign: align,
        cursor: click ? "pointer" : undefined,
        ...style,
      }}
      onClick={click}
    >
      {children}
    </h6>
  ) : type === "Paragraph" ? (
    <p
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color,
        lineHeight: line ? line + "px" : undefined,
        display: block ? "block" : "inline-block",
        marginBottom: bottom,
        marginTop: top,
        opacity: opacity,
        textAlign: align,
        cursor: click ? "pointer" : undefined,
        ...style,
      }}
      onClick={click}
    >
      {children}
    </p>
  ) : type === "Clickable" ? (
    <a
      href={href}
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color,
        lineHeight: line ? line + "px" : undefined,
        display: block ? "block" : "inline-block",
        marginBottom: bottom,
        marginTop: top,
        opacity: opacity,
        textAlign: align,
        cursor: "pointer",
        ...style,
      }}
      onClick={click}
    >
      {children}
    </a>
  ) : (
    <span
      style={{
        fontSize: size,
        fontWeight: weight,
        color: color,
        lineHeight: line ? line + "px" : undefined,
        display: block ? "block" : "inline-block",
        marginBottom: bottom,
        marginTop: top,
        opacity: opacity,
        textAlign: align,
        cursor: click ? "pointer" : undefined,
        ...style,
      }}
      onClick={click}
    >
      {children}
    </span>
  );

export default Component;
