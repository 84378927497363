import React from "react";
import { connect } from "react-redux";
import {
  HeroContainer,
  NavbarContainer,
  FooterContainer,
  ContentContainer,
  CalculateContainer,
  CalculatePickerContainer,
} from "../Containers";

import { Consent } from "../Components";
import { Settings } from "../Helpers";

class Component extends React.PureComponent {
  render() {
    return (
      <div>
        <NavbarContainer />
        {this.props.redux.show.result ? null : <HeroContainer />}
        {Settings.pickerMode ? (
          <CalculatePickerContainer key="calculatePickerContainer" />
        ) : (
          <CalculateContainer key="calculateContainer" />
        )}
        <ContentContainer />
        <FooterContainer />
        <Consent />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
