import React from "react";
import { ScreenClassRender } from "react-grid-system";

import Text from "../Atoms/Text";

const Component = ({ children, bottom, align }) => (
  <ScreenClassRender
    render={(screen) => (
      <Text
        type="Header"
        size={["xs"].includes(screen) ? 32 : 48}
        line={["xs"].includes(screen) ? 32 : 48}
        weight="600"
        block
        bottom={bottom}
        align={align}
      >
        {children}
      </Text>
    )}
  />
);

export default Component;
