import React from "react";
import { connect } from "react-redux";
import Moment from "moment/min/moment-with-locales";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import {
  Title,
  Button,
  Align,
  Input,
  Paragraph,
  Subtitle,
  Spacer,
  Text,
  Icon,
  Show,
  Padding,
} from "../Components";
import { Types, Fields } from "../Helpers";
import { API } from "../Modules";

const Info = ({ children }) => (
  <ScreenClassRender
    render={(screen) => (
      <Spacer bottom={40}>
        <div
          style={{
            padding: ["xs"].includes(screen) ? "8px 12px" : "12px 24px",
            background: "rgba(23,92,255,0.04)",
            borderRadius: 8,
            display: "flex",
            alignItems: ["xs"].includes(screen) ? "flex-start" : "center",
          }}
        >
          <Icon src="i/icon-info.svg" color="rgba(23,92,255)" right={8} />
          <Text
            weight="500"
            size={["xs"].includes(screen) ? 16 : 18}
            color="rgba(23,92,255)"
          >
            {children}
          </Text>
        </div>
      </Spacer>
    )}
  />
);

function stringToMoneyNumber(value) {
  var data = value.split(" ")[0];

  data = data.replace(/\./g, "");
  data = data.replace(/,/g, ".");

  return parseFloat(data);
}

function money(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      properties: {},
      debts: {},
      showCurrencyDebt: false,
      showAssetDebt: false,
      showCurrencyProperty: false,
      showAssetProperty: false,
      changed: false,
    };

    this.changeValueOfPropertyOrDebt = this.changeValueOfPropertyOrDebt.bind(
      this
    );
    this.calculate = this.calculate.bind(this);
    Moment.locale("tr");
  }

  changeValueOfPropertyOrDebt(type, key, value) {
    if (type === "debt") {
      var debts = this.state.debts;
      debts[key] = value;
      this.setState({ debts });
    }

    if (type === "property") {
      var properties = this.state.properties;
      properties[key] = value;
      this.setState({ properties });
    }
  }

  calculate() {
    var properties = 0;
    var debts = 0;
    var values = {};

    for (let [key, value] of Object.entries(this.state.properties)) {
      if (key !== "TRY") {
        properties += API.convert(key, value);
        values[key] = API.convert(key, 1);
      } else {
        properties += value;
      }
    }

    for (let [key, value] of Object.entries(this.state.debts)) {
      if (key !== "TRY") {
        debts += API.convert(key, value);
        values[key] = API.convert(key, 1);
      } else {
        debts += value;
      }
    }

    const datas = [];

    for (let [key, value] of Object.entries(values)) {
      Fields.map((field) => {
        if (field.type === key) {
          datas.push({ label: field.label, value });
        }

        return true;
      });
    }

    this.setState({ values: datas });

    var total = properties - debts;

    this.props.core("total", total);
    this.props.core("properties", properties);
    this.props.core("debts", debts);
    this.props.core("total", total);
    this.props.core("minimum", API.convert(Types.gold.GRAM, 80.18));
    this.props.core("show", { result: true });

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <ScreenClassRender
        key="screenRendered1"
        render={(screen) => (
          <Container
            style={{ paddingTop: this.props.redux.show.result ? 12 : 80 }}
            key="container1"
          >
            <Show show={this.props.redux.show.result}>
              {this.props.redux.show.result ? (
                <>
                  <Title bottom={16}>Zekat Hesabı Sonucunuz</Title>
                  <Padding
                    top={["xs"].includes(screen) ? 14 : 24}
                    right={["xs"].includes(screen) ? 14 : 24}
                    bottom={["xs"].includes(screen) ? 14 : 24}
                    left={["xs"].includes(screen) ? 14 : 24}
                    display="block"
                    style={{
                      borderRadius: 6,
                      border: "1px solid rgba(0,0,0,0.09)",
                      boxSizing: "border-box",
                    }}
                  >
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Row justify="space-between">
                          <Col xs={12} sm={12} md={8}>
                            <div>
                              <Title>
                                Toplam Varlık:{" "}
                                {money(this.props.redux.properties)} TL
                              </Title>
                              <Title>
                                Toplam Borç: {money(this.props.redux.debts)} TL
                              </Title>
                              <Title bottom={24}>
                                Net Varlık: {money(this.props.redux.total)} TL
                              </Title>
                              <Paragraph bottom={24} opacity={0.6}>
                                Nisap Miktarı (80,18 Gram Altın):{" "}
                                {money(this.props.redux.minimum)} TL
                              </Paragraph>
                            </div>
                          </Col>

                          {["xs"].includes(screen) ? null : (
                            <Col xs={12} sm={12} md={4}>
                              <Align justify="flex-end">
                                <Button
                                  click={() => {
                                    this.props.core("show", { result: false });
                                    window.scrollTo(0, 0);
                                  }}
                                  bottom={24}
                                >
                                  Hesabı Tekrarla
                                </Button>
                              </Align>
                            </Col>
                          )}
                        </Row>
                        {this.props.redux.total >= this.props.redux.minimum ? (
                          <div>
                            <Title>
                              Vermeniz Gereken Zekat:{" "}
                              {money(this.props.redux.total * 0.025)} TL
                            </Title>
                            <Paragraph bottom={24} opacity={0.6}>
                              (Net Varlığın %2,5'i)
                            </Paragraph>
                          </div>
                        ) : (
                          <div>
                            <Title>Vermeniz Gereken Zekat: Yok</Title>
                            <Paragraph bottom={24} opacity={0.6}>
                              Net varlığınız nisap miktarından düşük olduğu için
                              zekat vermeniz gerekmiyor.
                            </Paragraph>
                          </div>
                        )}
                        {["xs"].includes(screen) ? (
                          <Button
                            click={() => {
                              this.props.core("show", { result: false });
                              window.scrollTo(0, 0);
                            }}
                            bottom={24}
                          >
                            Hesabı Tekrarla
                          </Button>
                        ) : null}
                        <Paragraph bottom={8} top={24} size={14}>
                          Bu zekat hesabı {Moment().format("DD MMMM YYYY")}{" "}
                          tarihinde {Moment().format("HH:mm:ss")} saatindeki
                          piyasa verileri kullanılarak yapılmıştır.
                        </Paragraph>
                        <Paragraph
                          bottom={24}
                          size={14}
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {this.state.values.map((value, v) => (
                            <span
                              style={{ whiteSpace: "nowrap", marginRight: 6 }}
                            >
                              {value.label +
                                " " +
                                money(value.value, 2, ".") +
                                (this.state.values.length - 1 !== v
                                  ? " | "
                                  : "")}
                            </span>
                          ))}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Padding>
                </>
              ) : undefined}
            </Show>
            <Show show={!this.props.redux.show.result}>
              <Title bottom={16}>Zekat Hesaplama Aracı</Title>
              <Padding
                key="div2"
                top={["xs"].includes(screen) ? 14 : 24}
                right={["xs"].includes(screen) ? 14 : 24}
                bottom={["xs"].includes(screen) ? 14 : 24}
                left={["xs"].includes(screen) ? 14 : 24}
                display="block"
                style={{
                  borderRadius: 6,
                  border: "1px solid rgba(0,0,0,0.09)",
                  boxSizing: "border-box",
                }}
              >
                <Row align="center" justify="center">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Info>
                      Tahsilatı kesin olan alacaklarınızı varlıklarınıza
                      ekleyin.
                    </Info>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <div
                          style={{
                            position: "sticky",
                            top: 0,
                            background: "white",
                            display: "block",
                            zIndex: 3,
                          }}
                        >
                          <Title bottom={24}>Varlıklarım</Title>
                        </div>
                        <Subtitle bottom={24}>Para</Subtitle>
                        <Input
                          key="input1"
                          value={this.state.properties[Types.currency.TRY]}
                          placeholder="Miktar..."
                          label="Türk Lirası"
                          icon="/i/icon-try-default.svg"
                          bottom={12}
                          currency
                          suffix=" TL"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "property",
                              Types.currency.TRY,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input2"
                          value={this.state.properties[Types.currency.USD]}
                          placeholder="Miktar..."
                          label="Amerikan Doları"
                          icon="/i/icon-usd-default.svg"
                          bottom={12}
                          currency
                          suffix=" USD"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "property",
                              Types.currency.USD,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input3"
                          value={this.state.properties[Types.currency.EUR]}
                          placeholder="Miktar..."
                          label="Euro"
                          icon="/i/icon-eur-default.svg"
                          bottom={12}
                          currency
                          suffix=" EUR"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "property",
                              Types.currency.EUR,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Show show={!this.state.showCurrencyProperty}>
                          <Align justify="flex-end">
                            <Text
                              bottom={32}
                              weight="600"
                              color="#175CFF"
                              click={() =>
                                this.setState({ showCurrencyProperty: true })
                              }
                              type="Clickable"
                            >
                              Daha Fazla Döviz Göster +
                            </Text>
                          </Align>
                        </Show>
                        <Show show={this.state.showCurrencyProperty}>
                          <Input
                            key="input4"
                            value={this.state.properties[Types.currency.GBP]}
                            placeholder="Miktar..."
                            label="Sterlin"
                            bottom={12}
                            currency
                            suffix=" GBP"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.currency.GBP,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input5"
                            value={this.state.properties[Types.currency.KWD]}
                            placeholder="Miktar..."
                            label="Kuveyt Dinarı"
                            bottom={12}
                            currency
                            suffix=" KWD"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.currency.KWD,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input6"
                            value={this.state.properties[Types.currency.SAR]}
                            placeholder="Miktar..."
                            label="S. Arabistan Riyali"
                            bottom={24}
                            currency
                            suffix=" SAR"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.currency.SAR,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                        </Show>
                        <Show show={this.state.showCurrencyProperty}>
                          <Align justify="flex-end">
                            <Text
                              weight="600"
                              bottom={32}
                              color="#175CFF"
                              click={() =>
                                this.setState({ showCurrencyProperty: false })
                              }
                              type="Clickable"
                            >
                              Daha Az Döviz Göster -
                            </Text>
                          </Align>
                        </Show>
                        <Subtitle bottom={24}>Altın & Gümüş</Subtitle>

                        <Input
                          key="input7"
                          currency
                          value={this.state.properties[Types.gold.GRAM]}
                          precision="2"
                          placeholder="Gram..."
                          label="Gram Altın"
                          bottom={12}
                          suffix=" Gram"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "property",
                              Types.gold.GRAM,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input8"
                          value={this.state.properties[Types.gold.QUARTER]}
                          placeholder="Adet..."
                          label="Çeyrek Altın"
                          bottom={12}
                          suffix=" Adet"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "property",
                              Types.gold.QUARTER,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input9"
                          currency
                          value={this.state.properties[Types.gold.BRACELET]}
                          precision="2"
                          placeholder="Gram..."
                          label="22 Ayar Bilezik"
                          bottom={12}
                          suffix=" Gram"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "property",
                              Types.gold.BRACELET,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />

                        <Show show={!this.state.showAssetProperty}>
                          <Align justify="flex-end">
                            <Text
                              bottom={32}
                              weight="600"
                              color="#175CFF"
                              click={() =>
                                this.setState({ showAssetProperty: true })
                              }
                              type="Clickable"
                            >
                              Daha Fazla Seçenek Göster +
                            </Text>
                          </Align>
                        </Show>
                        <Show show={this.state.showAssetProperty}>
                          <Input
                            key="input10"
                            value={this.state.properties[Types.gold.HALF]}
                            placeholder="Adet..."
                            label="Yarım Altın"
                            bottom={12}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.gold.HALF,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input11"
                            value={this.state.properties[Types.gold.WHOLE]}
                            placeholder="Adet..."
                            label="Tam Altın"
                            bottom={12}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.gold.WHOLE,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input12"
                            value={this.state.properties[Types.gold.REPUBLIC]}
                            placeholder="Adet..."
                            label="Cumhuriyet Altını"
                            bottom={24}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.gold.REPUBLIC,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input13"
                            currency
                            value={this.state.properties[Types.silver.GRAM]}
                            precision="2"
                            placeholder="Gram..."
                            label="Gümüş"
                            bottom={12}
                            suffix=" Gram"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "property",
                                Types.silver.GRAM,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                        </Show>
                        <Show show={this.state.showAssetProperty}>
                          <Align justify="flex-end">
                            <Text
                              weight="600"
                              bottom={32}
                              color="#175CFF"
                              click={() =>
                                this.setState({ showAssetProperty: false })
                              }
                              type="Clickable"
                            >
                              Daha Az Seçenek Göster -
                            </Text>
                          </Align>
                        </Show>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <div
                          style={{
                            position: "sticky",
                            top: 0,
                            background: "white",
                            display: "block",
                            zIndex: 3,
                          }}
                        >
                          <Title bottom={24}>Borçlarım</Title>
                        </div>
                        <Subtitle bottom={24}>Para</Subtitle>
                        <Input
                          key="input14"
                          value={this.state.debts[Types.currency.TRY]}
                          placeholder="Miktar..."
                          label="Türk Lirası"
                          icon="/i/icon-try-default.svg"
                          bottom={12}
                          currency
                          suffix=" TL"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "debt",
                              Types.currency.TRY,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input15"
                          value={this.state.debts[Types.currency.USD]}
                          placeholder="Miktar..."
                          label="Amerikan Doları"
                          icon="/i/icon-usd-default.svg"
                          bottom={12}
                          currency
                          suffix=" USD"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "debt",
                              Types.currency.USD,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input16"
                          value={this.state.debts[Types.currency.EUR]}
                          placeholder="Miktar..."
                          label="Euro"
                          icon="/i/icon-eur-default.svg"
                          bottom={12}
                          currency
                          suffix=" EUR"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "debt",
                              Types.currency.EUR,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Show show={!this.state.showCurrencyDebt}>
                          <Align justify="flex-end">
                            <Text
                              bottom={32}
                              weight="600"
                              color="#175CFF"
                              click={() =>
                                this.setState({ showCurrencyDebt: true })
                              }
                              type="Clickable"
                            >
                              Daha Fazla Döviz Göster +
                            </Text>
                          </Align>
                        </Show>
                        <Show show={this.state.showCurrencyDebt}>
                          <Input
                            key="input17"
                            value={this.state.debts[Types.currency.GBP]}
                            placeholder="Miktar..."
                            label="Sterlin"
                            bottom={12}
                            currency
                            suffix=" GBP"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.currency.GBP,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input18"
                            value={this.state.debts[Types.currency.KWD]}
                            placeholder="Miktar..."
                            label="Kuveyt Dinarı"
                            bottom={12}
                            currency
                            suffix=" KWD"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.currency.KWD,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input19"
                            value={this.state.debts[Types.currency.SAR]}
                            placeholder="Miktar..."
                            label="S. Arabistan Riyali"
                            bottom={24}
                            currency
                            suffix=" SAR"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.currency.SAR,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                        </Show>
                        <Show show={this.state.showCurrencyDebt}>
                          <Align justify="flex-end">
                            <Text
                              weight="600"
                              bottom={32}
                              color="#175CFF"
                              click={() =>
                                this.setState({ showCurrencyDebt: false })
                              }
                              type="Clickable"
                            >
                              Daha Az Döviz Göster -
                            </Text>
                          </Align>
                        </Show>
                        <Subtitle bottom={24}>Altın & Gümüş</Subtitle>

                        <Input
                          key="input20"
                          currency
                          value={this.state.debts[Types.gold.GRAM]}
                          precision="2"
                          placeholder="Gram..."
                          label="Gram Altın"
                          bottom={12}
                          suffix=" Gram"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "debt",
                              Types.gold.GRAM,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input21"
                          value={this.state.debts[Types.gold.QUARTER]}
                          placeholder="Adet..."
                          label="Çeyrek Altın"
                          bottom={12}
                          suffix=" Adet"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "debt",
                              Types.gold.QUARTER,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />
                        <Input
                          key="input22"
                          currency
                          value={this.state.debts[Types.gold.BRACELET]}
                          precision="2"
                          placeholder="Gram..."
                          label="22 Ayar Bilezik"
                          bottom={12}
                          suffix=" Gram"
                          change={(event) =>
                            this.changeValueOfPropertyOrDebt(
                              "debt",
                              Types.gold.BRACELET,
                              stringToMoneyNumber(event.target.value)
                            )
                          }
                        />

                        <Show show={!this.state.showAssetDebt}>
                          <Align justify="flex-end">
                            <Text
                              bottom={32}
                              weight="600"
                              color="#175CFF"
                              click={() =>
                                this.setState({ showAssetDebt: true })
                              }
                              type="Clickable"
                            >
                              Daha Fazla Seçenek Göster +
                            </Text>
                          </Align>
                        </Show>
                        <Show show={this.state.showAssetDebt}>
                          <Input
                            key="input23"
                            value={this.state.debts[Types.gold.HALF]}
                            placeholder="Adet..."
                            label="Yarım Altın"
                            bottom={12}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.gold.HALF,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />

                          <Input
                            key="input24"
                            value={this.state.debts[Types.gold.WHOLE]}
                            placeholder="Adet..."
                            label="Tam Altın"
                            bottom={12}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.gold.WHOLE,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />

                          <Input
                            key="input25"
                            value={this.state.debts[Types.gold.REPUBLIC]}
                            placeholder="Adet..."
                            label="Cumhuriyet Altını"
                            bottom={24}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.gold.REPUBLIC,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input26"
                            currency
                            value={this.state.debts[Types.silver.GRAM]}
                            precision="2"
                            placeholder="Gram..."
                            label="Gümüş"
                            bottom={12}
                            suffix=" Gram"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.silver.GRAM,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                        </Show>
                        <Show show={this.state.showAssetDebt}>
                          <Align justify="flex-end">
                            <Text
                              weight="600"
                              bottom={32}
                              color="#175CFF"
                              click={() =>
                                this.setState({ showAssetDebt: false })
                              }
                              type="Clickable"
                            >
                              Daha Az Seçenek Göster -
                            </Text>
                          </Align>
                        </Show>
                        <Show show={false}>
                          <Input
                            key="input23"
                            value={this.state.debts[Types.gold.HALF]}
                            placeholder="Adet..."
                            label="Yarım Altın"
                            bottom={12}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.gold.HALF,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />

                          <Input
                            key="input24"
                            value={this.state.debts[Types.gold.WHOLE]}
                            placeholder="Adet..."
                            label="Tam Altın"
                            bottom={12}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.gold.WHOLE,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />

                          <Input
                            key="input25"
                            value={this.state.debts[Types.gold.REPUBLIC]}
                            placeholder="Adet..."
                            label="Cumhuriyet Altını"
                            bottom={24}
                            suffix=" Adet"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.gold.REPUBLIC,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                          <Input
                            key="input26"
                            currency
                            value={this.state.debts[Types.silver.GRAM]}
                            precision="2"
                            placeholder="Gram..."
                            label="Gümüş"
                            bottom={12}
                            suffix=" Gram"
                            change={(event) =>
                              this.changeValueOfPropertyOrDebt(
                                "debt",
                                Types.silver.GRAM,
                                stringToMoneyNumber(event.target.value)
                              )
                            }
                          />
                        </Show>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Align align="center" justify="center" top={48}>
                  <Button
                    click={this.calculate}
                    type="active"
                    style={{ marginLeft: 0 }}
                  >
                    Hesapla
                  </Button>
                  <Button
                    click={() => this.setState({ debts: {}, properties: {} })}
                    style={{ marginLeft: 12 }}
                  >
                    Temizle
                  </Button>
                </Align>
              </Padding>
            </Show>
          </Container>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
