import Airtable from "airtable";
import Store from "./Store";
import { Types, Settings } from "../Helpers";

export default class API {
  static async fire() {
    const response = await fetch("https://zekathesapla.net/api/currency/");
    const json = await response.json();
    Store.dispatch({ type: "CORE", key: "api", value: json });
  }

  static blogs() {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "appfx4jJ7E9utYOl3"
    );

    Base("Blogs")
      .select({
        filterByFormula:
          "{Project} = " +
          (Settings.pickerMode ? "Zekat Hesaplama" : "Zekat Hesapla"),
      })
      .firstPage(function (error, records) {
        var blogs = [];

        records.map((record) => {
          var blog = {};
          blog.id = record.id;
          blog.title = record.get("Title");
          blog.content = record.get("Content");
          blog.src = record.get("Attachment")[0].url;
          blogs.push(blog);
          return true;
        });

        Store.dispatch({ type: "CORE", key: "blogs", value: blogs });
      });

    return true;
  }

  static post(id) {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "appfx4jJ7E9utYOl3"
    );

    Base("Blogs").find(id, function (error, record) {
      var blog = {};
      blog.id = record.id;
      blog.title = record.get("Title");
      blog.content = record.get("Content");
      blog.src = record.get("Attachment")[0].url;

      Store.dispatch({ type: "CORE", key: "blog", value: blog });
    });

    return true;
  }

  static convert(currency, amount = 1) {
    const api = Store.getState().api;

    var data = api[currency][Types.keys.sell];
    var unit = 0;
    var changed = false;

    if (data.includes(",") && !data.includes(".") && !changed) {
      data = data.split(" ")[0];
      data = data.replace(/,/g, ".");
      unit = parseFloat(data);
      changed = true;
    }

    if (!data.includes(",") && data.includes(".") && !changed) {
      unit = parseFloat(data);
      console.log({ data });
      changed = true;
    }

    if (data.includes(",") && data.includes(".") && !changed) {
      data = data.split(" ")[0];
      data = data.replace(/,/g, ".");
      data = data.replace(/\./g, "");
      unit = parseFloat(data);
      changed = true;
    }

    return unit * amount;
  }

  static static(key) {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "app02iJcuHjpO7vyR"
    );

    Base("Static")
      .select({
        filterByFormula: "{Key} = '" + key + "'",
      })
      .firstPage(function (err, records) {
        records.forEach(function (record) {
          Store.dispatch({
            type: "CORE",
            key: "static",
            value: {
              title: record.get("Title"),
              content: record.get("Content"),
            },
          });
        });
      });

    return true;
  }
}
