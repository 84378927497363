const Types = {
  currency: {
    USD: "ABD DOLARI",
    EUR: "EURO",
    TRY: "TRY",
    SAR: "SUUDİ ARABİSTAN RİYALİ",
    GBP: "İNGİLİZ STERLİNİ",
    KWD: "KUVEYT DİNARI"
  },
  gold: {
    GRAM: "Gram Altın",
    QUARTER: "Çeyrek Altın",
    HALF: "Yarım Altın",
    WHOLE: "Tam Altın",
    REPUBLIC: "Cumhuriyet Altını",
    BRACELET: "22 Ayar Bilezik"
  },
  silver: {
    GRAM: "Gümüş",
  },
  keys: {
    buy: "Alış",
    sell: "Satış",
    type: "Tür",
  },
};

export default Types;
