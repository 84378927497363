import React from "react";

import { PostContainer, NavbarContainer, FooterContainer } from "../Containers";

class Component extends React.PureComponent {
  render() {
    return (
      <div>
        <NavbarContainer />
        <PostContainer blog={this.props.match.params.blog} />
        <FooterContainer />
      </div>
    );
  }
}

export default Component;
