import React from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Header,
  Paragraph,
  Ad,
} from "../Components";


class Component extends React.PureComponent {
  render() {
    return (
      <Container>
        <Row align="center" justify="center">
          <Col xs={12} sm={12} md={8} lg={8}>
            <Header bottom={32} align="center">Vermeniz gereken zekat tutarını hesaplayın</Header>
            <Paragraph bottom={32} align="center">
              Üzerinden 1 yıl geçen varlıklarınız için vermeniz gereken zekat tutarını öğrenmek istiyorsanız aşağıdaki hesaplama aracını kullanabilirsiniz.
            </Paragraph>
            <Ad />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Component;
