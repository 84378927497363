import React from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Title,
  Paragraph
} from "../Components";

class Component extends React.PureComponent {
  render() {
    return (
      <Container style={{ paddingTop: 140 }}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>Zekat nedir? Ne için zekat verilir?</Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Sözlük anlamıyla; temizlik, artmak, bereketli olmak, iyi ve düzgün
              olmak anlamında kullanılır. Dini anlamıyla ise; nisap miktarı
              zenginliğe sahip olan Müslümanların, Allah'ın hakkı olanlara
              verilmesini emrettiği belli miktarda malı vermesidir. Veren
              kimseyi cimrilik kirlerinden ve günahlardan temizlediği ve malında
              berekete vesile oldugu için kelime anlamı ile dini anlamı arasında
              bir bağ kurulabilir.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>Nisap miktarı nasıl hesaplanır?</Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Nisap miktarı 80,18 gram altındır. 80,18 gram veya buna karşılık
              gelen birikmiş parası (tahsili kesin alacaklar dahil) olan kişi
              dinen zengin sayılır ve dolayısıyla zekat vermekle yükümlüdür.
              Bazı ilmihâl kitaplarında nisap miktarı 92 gram altın olarak
              geçer. Ancak T.C. Diyanet İşleri Başkanlığı Din İşleri Yüksek
              Kurulu toplanarak nisap miktarını fakirlerin lehine 80,18 gram
              altın olacak şekilde belirlemiştir.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>
              Kimlere farzdır ve zekatın şartları nelerdir?
            </Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Müslüman, hür, akla sahib ve ergenlik çağına ermiş olmalıdır.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>Ne zaman verilir?</Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Eldeki zekata tabi varlıkların edinilmesinin üzerinden 1 yıl
              geçtikten sonra her zaman verilebilir. İslam'da böyle bir şart
              olmamasına rağmen ülkemizde çoğunlukla ramazan ayında verilmektir.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>Zekat nasıl verilir?</Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Fakirlere, hiçbir şeyi olmayanlara, borçlulara, yolculara ve
              öğrencilere verilir. Kardeşlere, amca, hala ve teyzelere
              verilebilirken; anne, baba ve onların anne-babaları ile çocuklar
              ve onların çocuklarına verilemez.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>
              Eve zekat düşer mi ve kiralık evin zekatı verilir mi?
            </Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Kişinin kendi oturduğu veya kiraya verilen ev ve gayrimenkuller
              zekata tabi değildir ancak bunların gelirleri nisap miktarını
              aşıyorsa bunlara zekat düşmektedir.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>Zekat nasıl hesaplanır?</Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Kişi ticaret erbabı ise işyerindeki malların alış fiyatına göre
              toplam tutarı ve birikmiş parası varsa bu biriken paranın zekatı
              verilmelidir. Burada dikkat edilecek en önemli nokta, eldeki
              varlıkların üzerinden 1 yıl geçmiş olması ve nisap miktarından
              daha fazla olmasıdır. Ne kadar verilir sorusunun cevabı ise zekata
              tabi varlıkların toplam değerinin %2,5'i ya da bir başka deyişle
              1/40'ı kadarıdır.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Title bottom={60}>Sadece nakit olarak mı verilir?</Title>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Paragraph bottom={60}>
              Hem nakit, hem mal, hem de ikisinin karışımı şeklinde verilebilir.
            </Paragraph>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Component;
