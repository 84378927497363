import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-grid-system";
import { Header, Project } from "../Components";
import { API } from "../Modules";

class Component extends React.Component {
  componentDidMount() {
    API.blogs();
  }

  render() {
    return (
      <Container>
        <Header bottom={32}>Blog</Header>
        <Row>
          {this.props.redux.blogs.map((blog, key) => (
            <Col xs={12} sm={12} md={6} lg={3} key={key}>
              <Project id={blog.id} title={blog.title} src={blog.src} content={blog.content.substring(0, 64) + "..."} />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
