import React from "react";
import { Navigator, Store, API } from "./Modules";
import { Types, Settings } from "./Helpers";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";

class App extends React.Component {
  async componentDidMount() {
    await API.fire();
    const minimum = API.convert(Types.gold.GRAM, 80.18);
    Store.dispatch({ type: "CORE", key: "minimum", value: minimum });
  }

  render() {
    return (
      <Provider store={Store}>
        <Helmet>
          <title>
            {Settings.pickerMode
              ? "ZekatHesaplama | Zekat Hesaplamanın En Pratik Yolu"
              : "ZekatHesapla.Net | Zekat Hesaplamanın En Kolay Yolu"}
          </title>
          <meta
            name="description"
            content={
              Settings.pickerMode
                ? "ZekatHesaplama.Net ile üzerinden 1 yıl geçen varlıklarınız için vermeniz gereken zekat miktarını kolayca hesaplayın. Zekat Hesaplamanın En Pratik Yolu."
                : "ZekatHesapla.Net ile üzerinden 1 yıl geçen varlıklarınız için vermeniz gereken zekat miktarını kolayca hesaplayın. Zekat Hesaplamanın En Kolay Yolu."
            }
          />
        </Helmet>
        <Navigator />
      </Provider>
    );
  }
}

export default App;
