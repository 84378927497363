import React from "react";
import { Container } from "react-grid-system";
import {
  Title,
  Paragraph,
  Header
} from "../Components";

class Component extends React.PureComponent {
  render() {
    return (
      <Container style={{ paddingTop: 140 }}>
        <Header align="center" bottom={24}>404</Header>
        <Title align="center" bottom={24}>Bir sorunlar karşılaştık!</Title>
        <Paragraph align="center">Aradığınız öğe sitemizde bulunamamaktadır. Süresi geçmiş ve ya yeri değiştirilmiş olabilir.</Paragraph>
      </Container>
    );
  }
}

export default Component;
