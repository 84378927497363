import React from "react";
import { connect } from "react-redux";
import { Container } from "react-grid-system";
import { Title } from "../Components";
import { API } from "../Modules";
import Markdown from "react-markdown";

class Component extends React.Component {
  componentDidMount() {
    API.post(this.props.blog);
  }

  render() {
    return (
      <Container>
        <div
          style={{
            width: "100%",
            maxHeight: 200,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4,
            boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.04)",
            marginBottom: 24,
          }}
        >
          <img
            alt={this.props.redux.blog.title}
            src={this.props.redux.blog.src}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <Title bottom={32}>{this.props.redux.blog.title}</Title>
        <Markdown source={this.props.redux.blog.content} />
      </Container>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
