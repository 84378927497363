import React from "react";

const Component = () => (
  <div
    style={{
      backgroundColor: "#F4F4F4",
      height: 80,
      borderRadius: 8
    }}
  />
);

export default Component;
