import React from "react";
import { ScreenClassRender } from "react-grid-system";

import { Settings } from "../../Helpers";

const Sources = {
  light: "/i/logo-light.svg",
  dark: "/i/logo-dark.svg",
};

const AlternativeSources = {
  light: "/i/logo-alternative-light.svg",
  dark: "/i/logo-alternative-dark.svg",
};

const Component = ({ theme = "dark" }) => (
  <ScreenClassRender
    render={(screen) => (
      <a
        href="/"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={Settings.pickerMode ? AlternativeSources[theme] : Sources[theme]}
          style={{ height: ["xs"].includes(screen) ? 20 : 28, width: "auto" }}
          alt=""
        />
      </a>
    )}
  />
);

export default Component;
