import React from "react";

import { BlogContainer, NavbarContainer, FooterContainer } from "../Containers";

import { Consent } from "../Components";

class Component extends React.PureComponent {
  render() {
    return (
      <div>
        <NavbarContainer no-blog />
        <BlogContainer />
        <FooterContainer />
        <Consent />
      </div>
    );
  }
}

export default Component;
